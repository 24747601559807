<template>
  <CRow>
    <div v-if="submitType === 'Update'">
      <CModal
          :title="
          submitType === 'Update'
            ? $lang.post_response.crud.edit
            : $lang.post_response.crud.create
        "
          size="lg"
          :show.sync="largeModal"
          :no-close-on-backdrop="true"
          color="primary"
      >
        <CAlert color="danger" closeButton :show.sync="dismissCountDown" fade>{{
            err_msg
          }}
        </CAlert>
        <CForm novalidate @submit.prevent="onSubmit">
          <CCardBody>
            <div
                class="form-group"
                :class="{ 'form-group--error': $v.post_response.response.$error }"
            >
              <label class="form__label"
              >{{ $lang.post_response.form.response }}
                <required_span/>
              </label>
              <textarea class="form-control" v-model="post_response.response"/>
              <small
                  class="error"
                  v-if="
                  $v.post_response.response.$error &&
                  !$v.post_response.response.required
                "
              >
                {{ $lang.post_response.validation.required.response }}
              </small>
            </div>
            <div class="form-group" :class="{ 'form-group--error': '' }">
              <label class="form__label"
              >{{ $lang.post_response.form.sender }}
                <required_span/>
              </label>
              <v-select
                  :options="senderOptions"
                  v-model="post_response.sender"
              ></v-select>
              <small
                  class="error"
                  v-if="
                  $v.post_response.sender.$error &&
                  !$v.post_response.sender.required
                "
              >
                {{ $lang.post_response.validation.required.sender }}
              </small>
            </div>
            <div class="form-group" :class="{ 'form-group--error': '' }">
              <label class="form__label"
              >{{ $lang.post_response.form.priority }}
                <required_span/>
              </label>
              <v-select
                  :options="priorityOptions"
                  v-model="post_response.priority"
              ></v-select>
              <small
                  class="error"
                  v-if="
                  $v.post_response.priority.$error &&
                  !$v.post_response.priority.required
                "
              >
                {{ $lang.post_response.validation.required.priority }}
              </small>
            </div>
          </CCardBody>
          <CCardFooter>
            <CButton
                type="submit"
                size="sm"
                color="primary"
                :disabled="submitted"
            >
              <CIcon name="cil-check-circle"/>
              {{ $lang.buttons.general.crud.submit }}
            </CButton>
          </CCardFooter>
        </CForm>
        <template #footer style="display: none">
          <CButton
              @click="darkModal = false"
              color="danger"
              style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CModal>
    </div>
    <div v-else>
      <CModal
          :title="
          submitType === 'Update'
            ? $lang.post_response.crud.edit
            : $lang.post_response.crud.view
        "
          size="lg"
          :show.sync="largeModal"
          :no-close-on-backdrop="true"
          color="primary"
      >
        {{ ViewResponse }}

        <template #footer style="display: none">
          <CButton
              @click="darkModal = false"
              color="danger"
              style="display: none"
          >Discard
          </CButton
          >
          <CButton
              @click="darkModal = false"
              color="success"
              style="display: none"
          >Accept
          </CButton
          >
        </template>
      </CModal>
    </div>

    <CCol col="12">
      <CCard>
        <CCardHeader>
          <div class="row">
            <CCol sm="6" class="align-self-center"></CCol>
            <CCol sm="6" class="align-self-center">
              <div class="text-sm-right mt-3 mt-sm-0">
                <CButtonGroup class="file-export">
                  <CButton color="secondary" disabled>
                    {{ $lang.buttons.export.title }}
                  </CButton>
                  <CButton
                      color="danger"
                      v-c-tooltip="$lang.buttons.export.pdf"
                      v-on:click="
                      exports(self, 'pdf', module, 'Post Response', self.editId)
                    "
                  >
                    <i class="fas fa-file-pdf"></i>
                  </CButton>
                  <VueJsonToCsv
                      class="btn btn-success"
                      v-c-tooltip="$lang.buttons.export.excel"
                      :json-data="json_data"
                      :labels="json_label"
                      :csv-title="json_title"
                  >
                    <i class="fas fa-file-excel"></i>
                  </VueJsonToCsv>
                </CButtonGroup>
              </div>
            </CCol>
          </div>
        </CCardHeader>
        <CCardBody>
          <CAlert
              color="success"
              closeButton
              :show.sync="dismissCountDownS"
              fade
          >{{ alertMessage }}
          </CAlert
          >
          <div id="people">
            <v-server-table
                :columns="columns"
                :url="listUrl"
                :options="options"
                ref="myTable"
                @loaded="
                exports(self, 'Excel', module, 'Post Response', self.editId)
              "
            >
              <template #actions="data">
                &nbsp;
                <CButton
                    color="primary"
                    v-c-tooltip="$lang.post_response.crud.view"
                    v-on:click="viewResponse(data.row.id)"
                >
                  <i class="fas fa-eye"></i>
                </CButton>
              </template>
            </v-server-table>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>
<script>
import Vue from "vue";
import VueForm from "vue-form";
import {
  PostResponseList,
  users,
  responsePriority,
  businessTraveller,
} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import options from "/src/validations/validations.js";
import {ServerTable} from "vue-tables-2";
import VueSweetalert2 from "vue-sweetalert2";
import {required} from "vuelidate/lib/validators";
import required_span from "../../components/layouts/general/required-span";
import VueJsonToCsv from "vue-json-to-csv";

Vue.use(VueSweetalert2);
Vue.use(ServerTable, {}, false);
Vue.use(VueForm, options);
export default {
  name: "PostResponseList",
  mixins: [Mixin],
  components: {
    required_span,
    VueJsonToCsv,
  },
  data() {
    return {
      submitted: false,
      json_data: [],
      json_label: {},
      json_title: "",
      err_msg: "",
      ViewResponse: "",
      largeModal: false,
      submitType: "",
      alertMessage: "test",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      self: this,
      msg: "",
      items: [],
      userOptions: [],
      senderOptions: [],
      priorityOptions: [],
      travellerOptions: [],
      activePage: 1,
      listUrl: "",
      module: PostResponseList,
      moduleUser: users,
      moduleResponsePriority: responsePriority,
      moduleTraveller: businessTraveller,

      post_response: {
        response: "",
        priority: "",
        sender: "",
      },

      columns: ["response", "username", "priority", "actions"],
      data: [],
      options: {
        headings: {
          response: this.$lang.post_response.table.response,
          username: this.$lang.post_response.table.username,
          priority: this.$lang.post_response.table.priority,
          actions: this.$lang.common.general.actions,
        },
        editableColumns: ["response", "username"],
        sortable: ["name", "isActive"],
        filterable: ["response", "username"],
      },
    };
  },

  validations: {
    post_response: {
      response: {
        required,
      },
      priority: {
        required,
      },
      sender: {
        required,
      },
    },
  },
  created() {
    let self = this;
    self.listUrl = "/posts/response/list/";
  },
  mounted() {
    let self = this;
    const id = this.$route.params.id;
    self.dismissCountDown = 0;
    self.editId = id;

    self.listUrl = this.listUrl + self.editId;

    //self.data = this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
    this.$root.$on("alert", (arg1, arg2) => {
    });
    if (localStorage.getItem("showAlert") !== "") {
      //  this.setAlert(true);
    }
    axios.get(this.listUrlApi(this.moduleUser)).then((response) => {
      response.data.map(function (value, key) {
        self.userOptions.push({value: value.id, label: value.username});
        self.senderOptions.push({value: value.id, label: value.username});
      });
    });
    axios.get(this.listUrlApi(this.moduleResponsePriority)).then((response) => {
      response.data.map(function (value, key) {
        self.priorityOptions.push({value: value.id, label: value.title});
      });
    });
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        if (route.query && route.query.page) {
          this.activePage = Number(route.query.page);
        }
      },
    },
  },
  methods: {
    changedIsActive(e) {
      let self = this;
      this.v = e.target.checked;
      if (this.v) {
        self.post_response.isActive = 1;
      } else {
        self.post_response.isActive = 0;
      }
    },

    createTableRow() {
      this.$router.push({name: "post_response Create"});
    },
    viewResponse(item) {
      let self = this;
      const id = item;
      self.responseId = item;

      axios
          .get(this.viewUrlApi(this.module, self.responseId), self.model)
          .then(function (response) {
            if (response) {
              const responseData = response.data;
              self.ViewResponse = responseData.response;
            } else {
              self.data = [];
            }
          });
      self.largeModal = true;
    },
    editTableRow(item) {
      let self = this;
      self.$v.$reset();
      self.submitType = "Update";
      const id = item;
      self.responseId = item;
      axios
          .get(this.viewUrlApi(this.module, self.responseId), self.model)
          .then(function (response) {
            if (response) {
              const responseData = response.data;
              self.post_response.response = responseData.response;

              self.post_response.priority = {
                value: responseData.priorityId,
                label: responseData.priority,
              };

              self.post_response.sender = {
                value: responseData.senderId,
                label: responseData.sender,
              };
            } else {
              self.data = [];
            }
          });

      self.largeModal = true;
    },
    rowClicked(item, index) {
      this.$router.push({path: `country/view/${item}`});
    },
    pageChange(val) {
      this.$router.push({query: {page: val}});
    },
    loadData() {
      let self = this;
      axios
          .get(this.listUrlApi(this.module), self.model)
          .then(function (response) {
            if (response) {
              self.data = response.data;
            } else {
              self.data = [];
            }
          });
    },

    onSubmit() {
      let self = this;
      this.$v.post_response.$touch();
      if (this.$v.post_response.$invalid) {
        self.submitted = false; //Enable Button
        this.submitStatus = "ERROR";
      } else {
        self.submitted = true; //Disable Button
        this.submitStatus = "PENDING";
        if (this.submitType === "Update") {
          setTimeout(() => {
            let self = this;
            const postData = {
              responseId: self.responseId,
              responseMsg: self.post_response.response,
              priority: self.post_response.priority.value,
              senderId: self.post_response.sender.value,
            };
            axios
                .post(this.updateUrlweb(this.module, self.responseId), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
          }, 500);
        } else {
          setTimeout(() => {
            let self = this;
            const postData = {
              socialLink: self.post_response.SocialLink,
              plateform: self.post_response.platform,
              isActive: self.post_response.isActive,
            };
            axios
                .post(this.createUrlWeb(this.module), postData)
                .then(function (response) {
                  self.submitted = false; //Enable Button
                  if (response.data.code === 200) {
                    self.alertMessage = response.data.message;
                    self.dismissCountDownS = 10;
                    self.largeModal = false;
                    self.$refs.myTable.refresh();
                  } else {
                    self.err_msg = response.data.message;
                    self.dismissCountDown = 10;
                  }
                })
                .catch(function (error) {
                  self.submitted = false; //Enable Button
                });
          }, 500);
        }
      }
    },
  },
};
</script>
